import React, { useEffect, useRef, useState } from "react"
import StandardLayout from "../../layouts/standard"
import coverImage from "../../images/about/our-mission/our-mission-cover.jpg"
import axios from "axios"
import { backendHost, remoteBackend } from "../../../config"

export default function Magazines(props) {
  const { magazines } = require("../../data.json")

  const [magazineUrl, setMagazineUrl] = useState("")

  const $ = typeof window !== "undefined" && window.$

  useEffect(() => {
    if ($) {
      const options = {
        responsive: true,
        autoFit: true,
        // autoHeight: true,
        // height: 80,

        padding: {
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
        },

        pdfUrl: magazineUrl,
        pdfAutoCreatePages: true,
        pdfBookSizeFromDocument: true,
      }

      $("#flipbook").kurasa(options)
    }
  }, [magazineUrl])

  const emailRef = useRef(null)
  const addSubscriber = () => {
    const { value } = emailRef.current
    const schema = /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/
    if (schema.test(value)) {
      axios
        .post(remoteBackend + "/subscribers", { email: value })
        .then(res => {
          alert("Thanks for subscribing")
        })
        .catch(err => {
          alert("Something went wrong. Please refresh and try again")
        })
    } else alert("Please enter a valid email")
  }

  return (
    <StandardLayout title="Stylamag">
      <div>
        <div
          class="uk-cover-container"
          data-uk-height-viewport="offset-bottom: 60;"
        >
          <img src={coverImage} alt="" data-uk-cover />
          <div class="uk-overlay-primary uk-position-cover"></div>
          <div class="uk-overlay uk-position-center-left uk-light">
            <div className="uk-container">
              <h1 className="uk-h1 uk-text-uppercase">Stylamag</h1>
            </div>
          </div>
        </div>
      </div>

      <section className="uk-section">
        <div className="uk-container uk-margin ">
          <div
            className="uk-child-width-1-3@s uk-child-width-1 uk-grid-small"
            data-uk-grid="masonry: true"
          >
            {magazines.map(mag => {
              const { name, file, thumbnail } = mag
              return (
                <div
                  class="uk-card  uk-card-default uk-padding-small  "
                  // style={{ height: "80vh", width: "auto" }}
                >
                  <div className="w-100 uk-background-cover h-100">
                    <img
                      src={thumbnail.publicURL}
                      style={{ height: "auto", width: "100%" }}
                    />
                  </div>
                  <h3 class="uk-card-title tc">{name}</h3>
                  <button
                    onClick={() => setMagazineUrl(file.publicURL)}
                    class="uk-button uk-button-primary uk-align-center   "
                    type="button"
                    uk-toggle="target: #modal-example"
                  >
                    Open Magazine
                  </button>{" "}
                </div>
              )
            })}
          </div>
        </div>

        <div className="uk-container uk-margin">
          <div className="uk-margin-large-top">
            <p class="uk-text-large uk-text-center">
              Join us on our journey into the brave new world of multifaceted
              design, decor and style. Email us at{" "}
              <a href="mailto:subscriptions@stylam.com">
                subscriptions@stylam.com
              </a>{" "}
              or subscribe to receive your copy of Stylamag.
            </p>
          </div>
          <div className="flex justify-center">
            <input
              placeholder="Your email"
              className="input-primary w-100"
              style={{ maxWidth: '300px' }}
              ref={emailRef}
            />
            <button className="uk-button uk-button-primary" onClick={() => addSubscriber()}>Subscribe</button>
          </div>
        </div>
      </section>
      <div id="modal-example" data-uk-modal>
        <div class="uk-modal-dialog " style={{width: "95vw", height: "90vh"}}>
          <button class="uk-modal-close-default m-close" type="button" uk-close>
            &#10006;
          </button>
          <div id="flipbook"></div>
        </div>
      </div>
    </StandardLayout>
  )
}
